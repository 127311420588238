import {html, store} from 'hybrids';
import {I18N} from "./planyourtrip-i18n-store.js";

function triggerMenu(host) {
    host.showOpenMenu = !host.showOpenMenu;
}

export const Header = {
    tag: 'pyt-header',
    moduleName: '',
    showOpenMenu: false,
    i18n: store(I18N),

    content: ({moduleName, showOpenMenu, i18n}) => html`
        <div>
            <header>
                <nav>
                    <div class="nav-wrapper">
                        <div class="brand-logo">
                            <a href="https://www.planyourtrip.travel" class="logo">
                                <img src="https://static.planyourtrip.travel/png/logo-100.png" alt="PlanYourTrip"/>
                            </a>

                            <a href="https://www.planyourtrip.travel" class="app-name hide-on-med-and-down">
                                PlanYourTrip.travel
                            </a>

                            <a href="/" class="module-name">
                                ${moduleName}
                            </a>
                        </div>

                        ${store.ready(i18n) && html`
                            <ul class="right ${showOpenMenu ? 'open z-depth-3' : ''}">

                                ${showOpenMenu && html`
                                    <li>
                                        <a onclick="${triggerMenu}">
                                            <i class="material-icons">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                     viewBox="0 0 24 24" width="24px" fill="#FFF">
                                                    <path d="M0 0h24v24H0V0z" fill="none"/>
                                                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
                                                </svg>
                                            </i>
                                        </a>
                                    </li>
                                `}

                                <li class="${showOpenMenu ? 'foo-bar' : 'hide-on-small-only'}">
                                    <a href="https://planner.planyourtrip.travel/create/roundtrip">
                                        ${i18n['header.menu.new-trip']}
                                    </a>
                                </li>
                                <li class="${showOpenMenu ? 'foo-bar' : 'hide-on-med-and-down'}">
                                    <a href="https://profile.planyourtrip.travel/trips">
                                        ${i18n['header.menu.my-trips']}
                                    </a>
                                </li>
                                ${showOpenMenu && html`
                                    <li>
                                        <a href="https://usermap.planyourtrip.travel/map">
                                            ${i18n['header.menu.my-usermap']}
                                        </a>
                                    </li>
                                `}
                                ${showOpenMenu && html`
                                    <li>
                                        <a href="https://points.planyourtrip.travel">
                                            ${i18n['header.menu.my-points']}
                                        </a>
                                    </li>
                                `}
                                <li class="${showOpenMenu ? 'foo-bar' : 'hide-on-med-and-down'}">
                                    <a href="https://discover.planyourtrip.travel">
                                        ${i18n['header.menu.discover']}
                                    </a>
                                </li>
                                ${showOpenMenu && html`
                                    <li>
                                        <a href="https://www.planyourtrip.to">
                                            ${i18n['header.menu.travel-book']}
                                        </a>
                                    </li>
                                `}
                                ${showOpenMenu && html`
                                    <li>
                                        <a href="https://profile.planyourtrip.travel">
                                            ${i18n['header.menu.my-profile']}
                                        </a>
                                    </li>
                                `}

                                ${!showOpenMenu && html`
                                    <li>
                                        <a onclick="${triggerMenu}">
                                            <i class="material-icons">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                     viewBox="0 0 24 24" width="24px" fill="#FFF">
                                                    <path d="M0 0h24v24H0V0z" fill="none"/>
                                                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
                                                </svg>
                                            </i>
                                        </a>
                                    </li>
                                `}
                            </ul>
                        `
                        }
                    </div>
                </nav>
            </header>
        </div>
    `,
};
