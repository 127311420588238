import {html, store} from 'hybrids';
import {I18N} from "./planyourtrip-i18n-store.js";

import 'cookieconsent';

const COOKIE_NAME = 'COOKIES_ACCEPTED';
const COOKIE_EVENT = function (status) {
    if (window.onConsentChange instanceof Function) {
        window.onConsentChange(status)
    }
}

function isCrawler() {
    return /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i
        .test(navigator.userAgent);
}

export const Consent = {
    tag: 'pyt-consent',
    i18n: store(I18N),
    content: ({i18n}) => {
        if (!store.ready(i18n) || isCrawler()) {
            return html``
        }

        let domain;
        const host = location.hostname.toLowerCase();
        if (host.indexOf('planyourtrip.travel') >= 0) {
            domain = '.planyourtrip.travel';
        } else if (host.indexOf('plan-your-trip.travel') >= 0) {
            domain = '.plan-your-trip.travel';
        } else if (host.indexOf('planyourtrip.to') >= 0) {
            domain = '.planyourtrip.to';
        } else if (host.indexOf('plan-your-trip.to') >= 0) {
            domain = '.plan-your-trip.to';
        } else {
            domain = host;
        }

        window.cookieconsent.initialise({
            container: document.getElementById("html"),
            palette: {
                popup: {
                    background: "#40a556",
                    text: "#ffffff"
                },
                button: {
                    background: "#367281",
                    text: "#ffffff"
                }
            },
            type: "opt-in",
            revokable: true,
            location: true,
            cookie: {
                name: COOKIE_NAME,
                path: "/",
                domain: domain,
                expiryDays: 365,
            },
            onInitialise: COOKIE_EVENT,
            onStatusChange: COOKIE_EVENT,
        });

        return html``;
    },
};

