import {store} from 'hybrids';

function getLanguage() {
    let browserLang = navigator.language || navigator.userLanguage || 'de';

    browserLang = browserLang.substring(0, 2);

    if (['de', 'en', 'pt', 'it'].indexOf(browserLang) !== -1) {
        return browserLang
    }

    return 'de';
}

export const I18N = {
    'planyourtrip.name': '',

    'header.menu.new-trip': '',
    'header.menu.my-trips': '',
    'header.menu.discover': '',
    'header.menu.my-usermap': '',
    'header.menu.my-points': '',
    'header.menu.travel-book': '',
    'header.menu.my-profile': '',

    'footer.text': '',
    'footer.links': '',
    'site.aboutus': '',
    'site.faq': '',
    'site.terms-of-use': '',
    'site.privacy': '',
    'site.impressum': '',
    'site.contact': '',
    'site.partner': '',
    'footer.apps': '',
    'module.dashboard.name': '',
    'module.discover.name': '',
    'module.planner.name': '',
    'module.usermap.name': '',
    'module.to.name': '',
    'module.points.name': '',
    'module.community.name': '',

    'common.cookies.title': '',
    'common.cookies.text': '',
    'common.ok': '',

    [store.connect]: {
        get: () => fetch(`https://messages.smarti18n.com/api/1/messages/findForAngularMessageSource?projectId=planyourtrip-base&locale=${getLanguage()}`)
            .then(res => res.json())
    }
}
