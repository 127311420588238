import {html, store} from 'hybrids';
import {I18N} from "./planyourtrip-i18n-store.js";

export const Footer = {
        tag: 'pyt-footer',
        i18n: store(I18N),
        content: ({i18n}) => html`
            <div>
                <footer class="page-footer">
                        <div class="container">
                            ${store.ready(i18n) && html`
                                <div class="row">
                                    <div class="col l8 s12">
                                        <h5 class="white-text">
                                            ${i18n['planyourtrip.name']}
                                        </h5>
                                        <p class="grey-text text-lighten-4">
                                            ${i18n['footer.text']}
                                        </p>
                                        <p class="languages">
                                            <a href="/de">Deutsch</a>
                                            <a href="/en">English</a>
                                            <a href="/pt">Português</a>
                                            <a href="/it">Italiano</a>
                                        </p>
                                    </div>
                                    <div class="col l2 s6">
                                        <h5 class="white-text">${i18n['footer.links']} </h5>
                                        <ul>
                                            <li><a class="grey-text text-lighten-3"
                                                   href="https://www.planyourtrip.travel/about-us">
                                                ${i18n['site.aboutus']}
                                            </a></li>
                                            <li><a class="grey-text text-lighten-3"
                                                   href="https://www.planyourtrip.travel/faq">
                                                ${i18n['site.faq']}
                                            </a></li>
                                            <li><a class="grey-text text-lighten-3"
                                                   href="https://www.planyourtrip.travel/terms-of-use">
                                                ${i18n['site.terms-of-use']}
                                            </a></li>
                                            <li><a class="grey-text text-lighten-3"
                                                   href="https://www.planyourtrip.travel/privacy">
                                                ${i18n['site.privacy']}
                                            </a></li>
                                            <li><a class="grey-text text-lighten-3"
                                                   href="https://www.planyourtrip.travel/impressum">
                                                ${i18n['site.impressum']}
                                            </a></li>
                                            <li><a class="grey-text text-lighten-3"
                                                   href="https://www.planyourtrip.travel/contact">
                                                ${i18n['site.contact']}
                                            </a></li>
                                            <li><a class="grey-text text-lighten-3"
                                                   href="https://www.planyourtrip.travel/partner">
                                                ${i18n['site.partner']}
                                            </a></li>
                                        </ul>
                                    </div>
                                    <div class="col l2 s6">
                                        <h5 class="white-text">${i18n['footer.apps']} </h5>
                                        <ul>
                                            <li><a class="grey-text text-lighten-3" href="https://www.planyourtrip.travel">
                                                ${i18n['module.dashboard.name']}
                                            </a></li>
                                            <li><a class="grey-text text-lighten-3"
                                                   href="https://discover.planyourtrip.travel">
                                                ${i18n['module.discover.name']}
                                            </a></li>
                                            <li><a class="grey-text text-lighten-3"
                                                   href="https://planner.planyourtrip.travel">
                                                ${i18n['module.planner.name']}
                                            </a></li>
                                            <li><a class="grey-text text-lighten-3"
                                                   href="https://usermap.planyourtrip.travel">
                                                ${i18n['module.usermap.name']}
                                            </a></li>
                                            <li><a class="grey-text text-lighten-3" href="https://www.planyourtrip.to">
                                                ${i18n['module.to.name']}
                                            </a></li>
                                            <li><a class="grey-text text-lighten-3"
                                                   href="https://points.planyourtrip.travel">
                                                ${i18n['module.points.name']}
                                            </a></li>
                                            <li><a class="grey-text text-lighten-3"
                                                   href="https://community.planyourtrip.travel">
                                                ${i18n['module.community.name']}
                                            </a></li>
                                        </ul>
                                    </div>
                                </div>
                            `
                            }
                        </div>
                        <div class="footer-copyright">
                            <div class="container">
                                &copy; 2022 PlanYourTrip.travel
                            </div>
                        </div>
                    </footer>
                </div>
            `,
    }
;

