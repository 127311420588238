import {define} from 'hybrids';

import {Footer} from './planyourtrip-footer.js';
import {Header} from './planyourtrip-header.js';
import {Consent} from "./planyourtrip-consent";


define(Footer);
define(Header);
define(Consent);
